<link href="https://static.canterbury.ac.nz/UCWeb/brand/red/css/wt-layout2.css" rel="stylesheet" type="text/css" />
<link href="https://static.canterbury.ac.nz/UCWeb/brand/red/css/wt-menus.css" rel="stylesheet" type="text/css" />
<link href="https://static.canterbury.ac.nz/UCWeb/brand/red/css/wt-3col-home.css" rel="stylesheet" type="text/css" />
<div id="maincontainer">
  <div id="topsection">
    <div id="topnav">
    </div>
  </div>
  <div id="bannersection"><img src="https://static.canterbury.ac.nz/UCWeb/brand/red/graphics/blank.png" id="printlogo" alt="logo"/>
    <div id="banner">
      <div id="sitetitle"><div id="top"><h2>{{appName}}</h2></div></div>
    </div>
  </div>
  <div id="contentwrapper">
    <div id="topgallerysection">
      <div id="topgallery"> </div>
    </div>
    <div id="leftcolumn">
    </div>
    <div id="contentcolumnsection">
      <div id="contentcolumn">
        <div id="related"></div>
        <div id="thirdColumn"></div>
        <div id="content">
          <br><br>
          <h1>Thanks for visiting {{appName}}</h1>
          <p><strong>Our system is temporarily unavailable.<br>
            We apologise for the inconvenience, please try again later.</strong></p>
            <div class="clear"></div>
          </div>
        </div>
    </div>
  </div>
  <div id="allfooter">
    <div id="gallerysection">
      <div id="gallery"> </div>
    </div>
    
    <div id="subfootersection">
      <div id="subfooter">
      </div>
    </div>
    <div id="footersection">
      <div id="footer">
      </div>
    </div>
  </div>
</div>
      
