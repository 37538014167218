/* eslint-disable id-blacklist */
/* eslint-disable max-lines-per-function */
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { EMPTY } from 'rxjs';
import { filter, first, debounceTime } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { DSHttpError } from '@shared/services/data-service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import {
  control,
  group,
  array,
  UCElementArray,
  UCElementGroup,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ProcessService } from '@shared/services/process/process.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-profile-template',
  templateUrl: './profile-template.component.html',
  styleUrls: ['./profile-template.component.scss'],
})
export class ProfileTemplateComponent implements OnInit {
  currentApplicant: Applicant;
  isLoading = false;
  log: Logger;
  originalFormState: string;
  phoneStrings = strings.components.molecules.phoneSelector;
  profileForm: UCElementGroup;
  profilePage: UntypedFormGroup;
  strings = strings.components.organisms.profilePage.profileTemplate;
  userCode: string;

  constructor(
    private applicantService: ApplicantService,
    private processService: ProcessService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private flashMessageService: FlashMessageService,
    private formMapper: FormModelMapperService,
    private modalService: ModalService,
    private userService: UserService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
    this.validUserGuard();
  }

  get otherNames(): UCElementArray {
    return this.profileForm.controls.personalDetails.controls.names.controls.name;
  }

  private createForm() {
    return group({
      personalDetails: group({
        birthDate: control({
          defaultState: '',
          validators: [UCValidators.validateDate],
          model: 'applicant',
          path: '/birthDate',
        }),
        names: group({
          legalName: control({
            defaultState: new Name({}),
            validators: [Validators.required],
            model: 'applicant',
            path: '/legalName',
          }),
          name: array({
            defaultState: [],
            validators: [],
            model: 'applicant',
            path: '/name',
          }),
        }),
        genderGroup: group({
          gender: control({
            model: 'applicant',
            path: '/gender',
            inMap: refDataToValue,
            outMap: valueToRefData,
            validators: [Validators.required],
          }),
        }),
        pronounsGroup: group({
          pronouns: control({
            model: 'applicant',
            path: '/pronouns/0/source',
            defaultState: null,
          }),
          customPronouns: control({
            model: 'applicant',
            path: '/pronouns/0/custom',
            defaultState: null,
            validators: [Validators.maxLength(200)],
          }),
          selected: control({
            model: 'applicant',
            path: '/pronouns/0/selected',
            defaultState: false,
          }),
        }),
        citizenship: group({
          ethnicities: array({
            defaultState: [],
            validators: [Validators.required],
            model: 'applicant',
            path: '/demographic/ethnicity',
          }),
          iwi: array({
            defaultState: [],
            validators: [],
            model: 'applicant',
            path: '/demographic/iwi',
          }),
        }),
      }),
      contactDetails: group({
        studyAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [],
          model: 'applicant',
          path: '/studyContactDetail/currentAddress',
        }),
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        landlineNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzLandlineValidator],
          model: 'applicant',
          path: '/contactDetail/landlineNumber',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
      }),
      emergencyDetails: group({
        email: control({
          defaultState: '',
          validators: [Validators.email],
          model: 'applicant',
          path: '/emergencyContactDetail/emailAddress',
        }),
        mobileNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/mobileNumber',
        }),
        alternatePhoneNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzAlternatePhoneValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/alternatePhone',
        }),
        firstName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/firstName',
        }),
        lastName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/surname',
        }),
        relationship: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactRelationship',
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/currentAddress',
        }),
      }),
      identityGroups: group({
        rainbowCommunityCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/rainbowCommunity/selected',
        }),
        refugeeBackgroundCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/refugeeBackground/selected',
        }),
        religionCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/selected',
        }),
        religionDropdown: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/source',
        }),
        religionTextField: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/custom',
          validators: [Validators.maxLength(200)],
        }),
      }),
    });
  }

  private isFormChanged(formValue) {
    return this.originalFormState && this.originalFormState !== JSON.stringify(formValue);
  }

  ngOnInit() {
    this.profileForm = this.createForm();
    this.profilePage = this.profileForm.asControl() as UntypedFormGroup;

    this.modalService.saveHandler = this.updateApplicant.bind(this);
    this.modalService.customStrings = this.modalService.defaultStrings;

    // Waits for the form value to stabilise when inserting model data into it
    this.profilePage.valueChanges.pipe(debounceTime(1000), first()).subscribe((formValue) => {
      this.originalFormState = JSON.stringify(formValue);
      this.setEmergencyContactValidation(formValue.emergencyDetails, 'emergencyDetails');
      this.setContactValidation(formValue.contactDetails, 'contactDetails');
    });

    this.profilePage.valueChanges.subscribe((formValue) => {
      this.modalService.shouldNavigate = !this.isFormChanged(formValue);
    });

    this.profilePage.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.modalService.showThirdButton = true;
        this.modalService.customStrings = { question: this.strings.unsavedModal.validFormQuestion };
      } else {
        this.modalService.showThirdButton = false;
        this.modalService.customStrings = { question: this.strings.unsavedModal.invalidFormQuestion };
      }
    });

    this.applicantService.applicant
      .pipe(
        filter((a) => !!a),
        first(),
      )
      .subscribe((a) => {
        this.currentApplicant = a;
        this.formMapper.updateFormFromModel(this.profileForm, a, null);
        if (a && !a.name.length) {
          this.otherNames.removeAt(0);
        }

        const studyEmail: string = (get(this.currentApplicant, 'studyContactDetail.emailAddress') as string) || '';
        if (studyEmail.match('uclive')) {
          this.userCode = studyEmail.split('@')[0];
        }

        setTimeout(() => {
          this.disableVerifiedControls(a);
        }, 0);
      });

    this.profilePage.get('personalDetails.citizenship.ethnicities').valueChanges.subscribe((val) => {
      // '2' is reference code for "New Zealand Maori"
      if (val.find((x) => x === '2')) {
        this.profilePage.get('personalDetails.citizenship.ethnicities').setValidators([Validators.required]);
      } else {
        this.profilePage.get('personalDetails.citizenship.ethnicities').setValidators([]);
      }
    });

    this.profilePage.get('emergencyDetails').valueChanges.subscribe((val) => {
      this.setEmergencyContactValidation(val, 'emergencyDetails');
    });

    this.profilePage.get('contactDetails').valueChanges.subscribe((val) => {
      this.setContactValidation(val, 'contactDetails');
    });
  }

  partiallyComplete = (numberGroup) => {
    if (numberGroup.number && !numberGroup.country) {
      return true;
    } else if (!numberGroup.number && numberGroup.country) {
      return true;
    }
    return false;
  };

  setContactValidation(formValues, formGroupKey: string) {
    const mobileIncomplete = this.partiallyComplete(formValues.mobileNum);
    const landlineIncomplete = this.partiallyComplete(formValues.landlineNum);

    const mobileNotPresent = !formValues.mobileNum.number && !formValues.mobileNum.country;
    const landlineNotPresent = !formValues.landlineNum.number && !formValues.landlineNum.country;

    if (mobileIncomplete) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors({ invalid: true });
    }
    if (landlineIncomplete) {
      this.profilePage.get(`${formGroupKey}.landlineNum`).setErrors({ invalid: true });
    }

    // if both mobile/landline are not present, then both fields are invalid and user can't submit form
    // if one is present, then we remove the errors from the other
    if (mobileNotPresent && landlineNotPresent) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors({ invalid: true });
      this.profilePage.get(`${formGroupKey}.landlineNum`).setErrors({ invalid: true });
    }

    const mobileValid = this.profilePage.get(`${formGroupKey}.mobileNum`).valid;
    const landlineValid = this.profilePage.get(`${formGroupKey}.landlineNum`).valid;
    if (landlineNotPresent && mobileValid) {
      this.profilePage.get(`${formGroupKey}.landlineNum`).setErrors(null);
    }
    if (mobileNotPresent && landlineValid) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors(null);
    }
  }

  setEmergencyContactValidation(formValues, formGroupKey: string) {
    const mobileIncomplete = this.partiallyComplete(formValues.mobileNum);
    const alternatePhoneIncomplete = this.partiallyComplete(formValues.alternatePhoneNum);

    const mobileNotPresent = !formValues.mobileNum.number && !formValues.mobileNum.country;
    const alternatePhoneNotPresent = !formValues.alternatePhoneNum.number && !formValues.alternatePhoneNum.country;

    if (mobileIncomplete) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors({ invalid: true });
    }
    if (alternatePhoneIncomplete) {
      this.profilePage.get(`${formGroupKey}.alternatePhoneNum`).setErrors({ invalid: true });
    }

    // if both mobile/alternatePhone are not present, then both fields are invalid and user can't submit form
    // if one is present, then we remove the errors from the other
    if (mobileNotPresent && alternatePhoneNotPresent) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors({ invalid: true });
      this.profilePage.get(`${formGroupKey}.alternatePhoneNum`).setErrors({ invalid: true });
    }

    const mobileValid = this.profilePage.get(`${formGroupKey}.mobileNum`).valid;
    const alternatePhoneValid = this.profilePage.get(`${formGroupKey}.alternatePhoneNum`).valid;
    if (alternatePhoneNotPresent && mobileValid) {
      this.profilePage.get(`${formGroupKey}.alternatePhoneNum`).setErrors(null);
    }
    if (mobileNotPresent && alternatePhoneValid) {
      this.profilePage.get(`${formGroupKey}.mobileNum`).setErrors(null);
    }
  }

  validUserGuard() {
    if (!get(this.userService.user, 'studentId')) {
      this.router.navigate(internalUrls.fourOhFour);
    }
  }

  private disableVerifiedControls(applicant?: Applicant) {
    if (get(applicant, 'validatedBirthDate')) {
      this.profilePage.get('personalDetails.birthDate').disable();
    }

    if (get(applicant, 'legalName.validated')) {
      this.profilePage.get('personalDetails.names.legalName').disable();
    }
  }

  copyPermanentToEmergencyAddress() {
    const permanentAddress = this.profilePage.get('contactDetails.address').value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.profilePage.get('emergencyDetails.address').patchValue(addressCopy);
  }

  public copyPermanentToStudyAddress() {
    const permanentAddress = this.profilePage.get('contactDetails.address').value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.profilePage.get('contactDetails.studyAddress').patchValue(addressCopy);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  updateApplicant(): Promise<any> {
    if (!this.currentApplicant) {
      this.log.error('tried to update applicant, but no applicant has been loaded yet');
      Promise.reject('tried to update applicant, but no applicant has been loaded yet');
    }

    this.formMapper.updateModelFromForm(this.profileForm, this.currentApplicant);

    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);

    // Hack to prevent nulls in what should be an array
    this.currentApplicant.demographic.ethnicity = this.currentApplicant.demographic.ethnicity.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);
    // '2' is reference code for "New Zealand Maori"
    const isMaori = this.currentApplicant.demographic.ethnicity.find((e) => e.code === '2');
    if (!isMaori) {
      this.currentApplicant.demographic.iwi = [];
    }

    this.isLoading = true;
    return this.applicantService
      .updateApplicant(this.currentApplicant)
      .toPromise()
      .then(() => this.processService.syncProfile().toPromise())
      .then(() => {
        this.isLoading = false;
        this.modalService.shouldNavigate = true;
        this.log.info('Updated applicant successfully');
        this.flashMessageService.pushSuccess(strings.components.template.dashboard.applicantUpdate, { countdown: 10 });
        this.router.navigate(internalUrls.dashboard);
        Promise.resolve();
      })
      .catch((e: DSHttpError) => {
        this.log.error('Error updating applicant', e);
        this.isLoading = false;
        return EMPTY;
      });
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
