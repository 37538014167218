import { Component, OnInit } from '@angular/core';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { UserService } from '@shared/services/user/user.service';
import { DocumentService } from '@shared/services/document/document.service';
import { filter } from 'rxjs/operators';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';

@Component({
  selector: 'uc-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.scss'],
})
export class DashboardTemplateComponent implements OnInit {
  constructor(
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private userService: UserService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    this.changeOfEnrolmentService.getChangeOfEnrolment(true).subscribe();

    this.userService.userDetail.pipe(filter((user) => !!user && !!user.impersonated)).subscribe(() => {
      this.documentService.getImportantDocuments();
    });
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
