<a
  (click)="jumpToContent('main-content')"
  href="javascript:void(0)"
  class="skip-to-content-link"
  >Skip to main content</a
>
<uc-header-bar (openSidenav)="navBar.isOpen = true"></uc-header-bar>
<uc-side-nav #navBar></uc-side-nav>
<div
  id="main-content"
  tabindex="-1"
  class="profile-content"
>
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header title="{{ strings.myProfile }}">
        <uc-message-banner class="heading-banners margin-btm-20"
          >{{ strings.mandatoryBanner }}<uc-required-field></uc-required-field
        ></uc-message-banner>
      </uc-form-header>

      <uc-section *ngIf="currentApplicant?.identity?.studentNumber">
        <uc-section-header [title]="strings.studyDetailsHeader"> </uc-section-header>

        <uc-subsection>
          <uc-form-input>
            <uc-subsection-question>
              <div *ngIf="currentApplicant?.identity?.studentNumber">
                {{ strings.studentId }}
                <div class="read-only-text">{{ currentApplicant.identity.studentNumber }}</div>
              </div>
              <div *ngIf="currentApplicant?.studyContactDetail?.emailAddress">
                {{ strings.studyEmail }}
                <div class="read-only-text">{{ currentApplicant.studyContactDetail.emailAddress }}</div>
              </div>
              <div *ngIf="userCode">
                {{ strings.usercode }}
                <div class="read-only-text">{{ userCode }}</div>
              </div>
            </uc-subsection-question>
          </uc-form-input>
        </uc-subsection>
      </uc-section>

      <uc-personal-details
        [personalDetails]="profilePage.get('personalDetails')"
        [personalDetailsEl]="profileForm.controls['personalDetails']"
      >
      </uc-personal-details>

      <uc-section>
        <uc-identity-groups [identityGroups]="profilePage.get('identityGroups')"></uc-identity-groups>
      </uc-section>

      <uc-section [formGroup]="profilePage.get('contactDetails')">
        <uc-section-header title="{{ strings.contactDetails }}"> </uc-section-header>

        <uc-subsection>
          <uc-form-input>
            <uc-string-input
              testSelector="contact-email"
              class="input-medium"
              [label]="strings.emailLabel"
              formControlName="email"
              required="yes"
            ></uc-string-input>
          </uc-form-input>
        </uc-subsection>

        <uc-subsection>
          <uc-form-input>
            <uc-subsection-question [required]="true">{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
            <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
            <uc-phone-selector
              testSelector="profile-mobile"
              formControlName="mobileNum"
              labelText="{{ phoneStrings.mobileLabel }}"
            >
            </uc-phone-selector>
          </uc-form-input>
          <uc-form-input>
            <uc-subsection-minor-text>{{ phoneStrings.landlineHint }}</uc-subsection-minor-text>
            <uc-phone-selector
              testSelector="profile-landline"
              formControlName="landlineNum"
              labelText="{{ phoneStrings.landlineLabel }}"
            >
            </uc-phone-selector>
          </uc-form-input>
        </uc-subsection>

        <uc-subsection>
          <uc-form-input>
            <uc-subsection-question>{{ strings.homeAddressQuestion }}</uc-subsection-question>
            <uc-address-selector
              testSelector="home"
              formControlName="address"
            ></uc-address-selector>
          </uc-form-input>
        </uc-subsection>
      </uc-section>

      <uc-section [formGroup]="profilePage.get('contactDetails')">
        <uc-section-header title="{{ strings.studyAddressHeader }}">
          <p>
            {{ strings.studyAddressQuestion }}
          </p>
          <uc-button
            testSelector="copy-address"
            class="copy-address"
            (click)="copyPermanentToStudyAddress()"
          >
            {{ strings.copyAddressButton }}
          </uc-button>
        </uc-section-header>
        <uc-subsection>
          <uc-form-input>
            <uc-address-selector
              testSelector="study"
              [required]="false"
              formControlName="studyAddress"
            ></uc-address-selector>
          </uc-form-input>
        </uc-subsection>
      </uc-section>

      <uc-emergency-contact
        [emergencyDetails]="profilePage.get('emergencyDetails')"
        [copyAddressButtonText]="strings.copyAddressButton"
        (copyAddress)="copyPermanentToEmergencyAddress()"
      >
      </uc-emergency-contact>

      <div class="page-actions">
        <uc-button
          testSelector="update"
          class="submit-btn"
          (click)="updateApplicant()"
          [disabled]="profilePage.status === 'INVALID'"
          [isLoading]="isLoading"
        >
          {{ strings.updateButton }}</uc-button
        >
      </div>
    </uc-form-template>
  </div>
  <uc-footer></uc-footer>
</div>
