import { RouterModule, Routes } from '@angular/router';

import { AccountSettingsComponent } from '@app/components/templates/account-settings/account-settings.component';
import { LoginTemplateComponent } from '@app/components/templates/login-template/login-template.component';
import { ProcessHydrateOverlayComponent } from '@shared/components/atoms/process-hydrate-overlay/process-hydrate-overlay.component';
import { CoursePlannerComponent } from '@shared/components/templates/course-planner/course-planner.component';
import { DashboardTemplateComponent } from '@shared/components/templates/dashboard-template/dashboard-template.component';
import { DocumentTemplateComponent } from '@shared/components/templates/document-template/document-template.component';
import { InformationRequiredComponent } from '@shared/components/templates/information-required-template/information-required-template.component';
import { ManageMyStudyTemplateComponent } from '@shared/components/templates/manage-my-study-template/manage-my-study-template.component';
import { OutageTemplateComponent } from '@shared/components/templates/outage-template/outage-template.component';
import { PageNotFoundTemplateComponent } from '@shared/components/templates/page-not-found-template/page-not-found-template.component';
import { ProfileTemplateComponent } from '@shared/components/templates/profile-template/profile-template.component';
import { SsoTemplateComponent } from '@shared/components/templates/sso-template/sso-template.component';
import { AuthOnloadGuard } from '@shared/services/guards/auth-onload.guard';
import { FeatureGuard } from '@shared/services/guards/feature.guard';
import { OutageGuard } from '@shared/services/guards/outage.guard';
import { StudentIdGuard } from '@shared/services/guards/student-id.guard';
import { UnsavedDataGuard } from '@shared/services/guards/unsaved-data.guard';
import { UserAuthenticatedGuardService } from '@shared/services/guards/user-authenticated-guard.service';
import { interceptBackButtonForModalsGuard } from '@shared/services/modal/intercept-back-button-for-modals.guard';

import { AuthActionComponent } from './components/templates/auth-action/auth-action.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginTemplateComponent,
    canActivate: [AuthOnloadGuard],
    data: { title: 'Login | Application to Enrol | UC' },
  },
  {
    path: 'sso',
    component: SsoTemplateComponent,
    canActivate: [UserAuthenticatedGuardService],
  },
  {
    path: 'apply',
    loadChildren: () => import('@shared/applicant/applicant.module').then((m) => m.ApplicantModule),
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Apply | Application to Enrol | UC' },
  },
  {
    path: 'documents',
    component: DocumentTemplateComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Document Library | Application to Enrol | UC' },
  },
  {
    path: 'information-required/:year',
    component: InformationRequiredComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Information Required | Application to Enrol | UC' },
  },
  {
    path: '',
    component: DashboardTemplateComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Dashboard | Application to Enrol | UC' },
  },
  {
    path: 'manage-my-study',
    component: ManageMyStudyTemplateComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Manage my study | Application to Enrol | UC' },
  },
  {
    path: 'profile',
    component: ProfileTemplateComponent,
    canActivate: [UserAuthenticatedGuardService, FeatureGuard],
    canDeactivate: [UnsavedDataGuard],
    data: { title: 'My Profile | Application to Enrol | UC' },
  },
  {
    path: 'hydrate',
    component: ProcessHydrateOverlayComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Preparing your account | Application to enrol | UC' },
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [UserAuthenticatedGuardService, FeatureGuard],
    data: { title: 'Account Settings | Application to enrol | UC' },
  },
  {
    path: 'auth-action',
    component: AuthActionComponent,
    data: { title: 'Authentication actions | Application to enrol | UC' },
  },
  {
    path: 'courses/:applicationYear/:enrolmentPriority/:qualPriority',
    component: CoursePlannerComponent,
    canActivate: [UserAuthenticatedGuardService, StudentIdGuard],
  },
  {
    path: 'outage',
    component: OutageTemplateComponent,
    data: { title: 'UC Outage | Application to Enrol | UC' },
    canActivate: [OutageGuard],
  },
  {
    path: '**',
    component: PageNotFoundTemplateComponent,
    data: { title: '404 | Application to Enrol | UC' },
  },
];

routes.forEach((route) => {
  route.canDeactivate = route.canDeactivate || [];
  route.canDeactivate.push(interceptBackButtonForModalsGuard);
});

export const appRoutesModule = RouterModule.forRoot(routes, {});
