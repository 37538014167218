<uc-responsive-grid [breakpoints]="breakpoints">

  <mat-grid-list cols="4" rowHeight="260px">

    <mat-grid-tile colspan="2" rowspan="2">
      <uc-tile>
        <uc-application-tile></uc-application-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{strings.enrolmentInfoLink}}">
        <uc-static-link-dashboard-tile
          icon="cap"
          header="{{strings.enrolmentInfo}}">
        </uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{strings.codeOfConductLink}}">
        <uc-static-link-dashboard-tile
          icon="download"
          header="{{strings.codeOfConduct}}">
        </uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{strings.healthCentreLink}}">
        <uc-static-link-dashboard-tile
          icon="health"
          header="{{strings.healthCentre}}">
        </uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{strings.CWYNLink}}">
        <uc-static-link-dashboard-tile
          icon="link-alt"
          header="{{strings.CWYN}}">
        </uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

  </mat-grid-list>
</uc-responsive-grid>
