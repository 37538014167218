import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { APP_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss'],
})
export class LoginTemplateComponent implements OnInit {
  strings = strings.components.organisms;
  appName = strings.labels.appName;
  loginFormStrings = strings.components.organisms.loginForm;
  isViewingLogin = true;
  urls = externalUrls;
  isRestricted = true;
  duplicateEmail = '';
  appNameEnv = APP_NAMES.student;

  constructor(private userService: UserService, private router: Router, public ipLocationService: IPLocationService) {}

  ngOnInit() {
    this.userService.isInOutage.subscribe((inOutage) => {
      if (inOutage) {
        this.router.navigate(internalUrls.outage);
      }
    });

    this.ipLocationService.isFirebaseRestricted.subscribe((isRestricted) => {
      this.isRestricted = isRestricted;
    });
  }

  populateEmailField(event?) {
    const email = get(event, 'email', '');
    this.duplicateEmail = email;
  }
}
