import { Component, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-dashboard-tiles',
  templateUrl: './dashboard-tiles.component.html',
  styleUrls: ['./dashboard-tiles.component.scss'],
})
export class DashboardTilesComponent implements OnInit {
  breakpoints = [
    [550, 1],
    [1024, 2],
    [1920, 4],
  ];
  strings = strings.components.template.dashboard;
  constructor() {}

  ngOnInit() {}
}
