/* eslint-disable max-len */

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// Other student web link http://ucapp1u.canterbury.ac.nz/Sandpit/JadeSMS/Login.aspx

import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

import { UCEnvironment } from '@shared/environment-definition';
import { UserTypes } from '@shared/models/user';

export const environment: UCEnvironment = {
  envName: 'default',
  academicYearStart: '01/03',
  scope: UserTypes.student,
  production: false,
  apiRoot: '/api',
  sharedModelSerializerMode: 'PUT',
  scholarshipsUrl: 'https://scholarshipscanterburystaging.communityforce.com/SAML/AssertionConsumerService.aspx',
  logLevel: 'info',
  envColour: 'pink',
  // use the dsn sentry url here from sentry.catalyst.net.nz
  sentryUrl: null,
  firebaseRestrictedCountryCodes: ['CN'],
  firebase: {
    projectId: 'myuctest-52406',
    apiKey: 'AIzaSyCqAHeK_uPC2W8AW3wGT5ovZp0jicSVUEI',
    authDomain: 'myuctest-52406.firebaseapp.com',
    databaseURL: 'https://myuctest-52406.firebaseio.com',
    storageBucket: 'myuctest-52406.appspot.com',
    messagingSenderId: '203334776419',
  },
  azureB2C: {
    msalConfig: {
      auth: {
        clientId: 'f5a29c9e-c08b-43b5-b655-c17442806941',
        authority:
          'https://ucliveidentityb2ctest.b2clogin.com/ucliveidentityb2ctest.onmicrosoft.com/B2C_1a_signup_signin',
        knownAuthorities: ['ucliveidentityb2ctest.b2clogin.com'],
        redirectUri: 'https://myuctest.canterbury.ac.nz/b2c',
        postLogoutRedirectUri: 'https://myuctest.canterbury.ac.nz/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: (logLevel: LogLevel, message: string) => {
            console.log('MSALconfig:', message);
          },
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    },
    loginRequest: {
      scopes: ['openid', 'offline_access'],
    },
  },
  useFakeBackend: {
    user: true,
    application: true,
    applicant: true,
    course: true,
    enrolment: true,
    process: true,
    qualification: true,
    referenceData: true,
    document: true,
    // turn it off because otherwise we get spammed with an annoying modal all the time
    jadeHydrate: false,
    notification: true,
    userActivity: true,
    agency: true,
    condition: true,
    enrolmentSummary: true,
    changeOfEnrolment: true,
    courseResults: true,
  },
  features: {
    unlockStages: false,
    notifications: false,
    profile: true,
    course: false,
    status: false,
    applications: false,
    sendEmail: true,
    accountSettings: true,
    offerLetter: true,
    ipLocation: false,
  },
  externalScripts: {
    googleMaps: {
      src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA1U9sfT0g4sPJsvs9EJ8m7TkzQ8yUPr8E&libraries=places',
    },
    cxOne: {
      fileName: 'cxoneLiveChat',
      params: {
        remoteScriptUrl: 'https://web-modules-de-au1.niceincontact.com/loader/1/loader.js',
        fnName: 'cxone',
        tenantId: 1352,
        channelId: 'chat_0fc9d3fb-c09b-4957-b043-d7a5cecc6713',
      },
    },
  },
};
