<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link"
  >Skip to main content</a
>
<uc-header-bar [hasSideNav]="true" (openSidenav)="navBar.isOpen = true"></uc-header-bar>
<uc-side-nav #navBar></uc-side-nav>
<div id="main-content" tabindex="-1" class="overview__content">
  <div class="overview__inner-content">
    <uc-form-template [class.overview__hide-application-btn]="!canStartNewApplication">
      <uc-go-back-link [targetPath]="dashboardPath" [customText]="strings.goToDashboard"></uc-go-back-link>
      <uc-form-header title="{{ strings.title }}">
        <span class="overview__description">{{ strings.description }}</span>
        <button
          [attr.data-cy]="'new-app'"
          *ngIf="canStartNewApplication"
          uc-button
          class="overview__new-application-btn"
          [isLoading]="isLoading"
          (click)="transitionToNewApplication()"
        >
          {{ strings.newApplicationButtonText }}
        </button>
      </uc-form-header>
    </uc-form-template>

    <uc-spinner *ngIf="displaySpinner"></uc-spinner>

    <p
      class="overview__no-applications"
      *ngIf="!applicationSummaries?.length && !archivedEnrolments?.length && !displaySpinner"
    >
      {{ strings.noActiveApplications }}
    </p>

    <div class="overview__active-application" *ngFor="let appYear of applicationYears">
      <uc-application-overview
        [year]="appYear.year"
        [expanded]="appYear.expanded"
        (updateParams)="updateParams($event)"
        (updateViewData)="fetchViewData()"
      ></uc-application-overview>
    </div>
  </div>

  <uc-footer></uc-footer>
</div>
