import { Component, OnInit } from '@angular/core';
import { imageList } from '@shared/components/atoms/login-image/image-list';

@Component({
  selector: 'uc-login-image',
  templateUrl: './login-image.component.html',
  styleUrls: ['./login-image.component.scss'],
})
export class LoginImageComponent implements OnInit {
  backgroundImageUrl: string;
  images = imageList;

  ngOnInit() {
    this.backgroundImageUrl = this.getRandomImage();
  }

  getRandomImage() {
    const randomNum = Math.floor(Math.random() * Math.floor(this.images.length));
    const fileName = this.images[randomNum];
    return `url(assets/img/faces/${fileName})`;
  }
}
