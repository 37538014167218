import { Component } from '@angular/core';
import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-outage-template',
  templateUrl: './outage-template.component.html',
})
export class OutageTemplateComponent {
  appName = strings.labels.appName;
}
