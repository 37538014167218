<div class="login-wrapper">
  <div class="container-fluid">
    <div class="content-panels row">
      <div class="content-panel col-md-6 col-lg-4 col-xlg-3">
        <div class="uc-banner">
          <a class="banner-logo" [href]="urls.ucHomepage">
            <img class="banner-logo-image" src="assets/img/uc-logo@2x.png" alt="UC Logo" />
          </a>
          <h1 class="myuc-logo accent-font">{{ appName }}</h1>
        </div>
        <ul class="tabs">
          <li class="tabs__tab-col">
            <a
              class="tabs__tab-link create-link"
              href="javascript:void(0);"
              (click)="isViewingLogin = false"
              [class.active]="isViewingLogin === false"
              >{{ strings.createAccountForm.createAccountButton }}</a
            >
          </li>
          <li class="tabs__tab-col">
            <a
              class="tabs__tab-link login-link"
              href="javascript:void(0);"
              (click)="isViewingLogin = true"
              [class.active]="isViewingLogin"
              >{{ strings.loginForm.loginButton }}</a
            >
          </li>
        </ul>
        <div class="max-width-320">
          <span *ngIf="!isRestricted">
            <uc-social-login></uc-social-login>
            <div class="divider">
              <span class="divider__text">or</span>
            </div>
          </span>
          <uc-login-form
            *ngIf="isViewingLogin"
            [isRestricted]="isRestricted"
            (goToCreate)="isViewingLogin = false"
            [duplicateEmail]="duplicateEmail"
            [appName]="appNameEnv"
          >
          </uc-login-form>

          <span *ngIf="isViewingLogin">
            <div class="divider">
              <span class="divider__text">{{ loginFormStrings.agenciesHeader }}</span>
            </div>

            <uc-button
              testSelector="login"
              class="large rounded secondary agent-login"
              [externalLink]="urls.agentPortal"
              replaceUrl="true"
            >
              {{ loginFormStrings.agentLogin }}
            </uc-button>
          </span>

          <uc-create-account-form
            (goToLogin)="populateEmailField($event); isViewingLogin = true"
            *ngIf="isViewingLogin === false"
          >
          </uc-create-account-form>
          <p [innerHtml]="strings.loginForm.contactCentre"></p>
        </div>
      </div>
      <div class="col-md-6 col-lg-8 col-xlg-9 hidden-sm hidden-xs hero-image-container">
        <uc-login-image></uc-login-image>
      </div>
    </div>
  </div>
</div>
